/* .primary{
   background-color:#2FC3B9; 
}

.navbar-custom-style{
    position: fixed !important;
    width: 88% !important;
    background-color: white !important;
    left: 12% !important;
    background: white !important;   
}


.qatl-main-btn{
    border-radius: 50px; 
    border: 1px solid #2fc3b9 !important;   
}

.qatl-main-btn-sec{
    color:1px solid #08A9A8 !important;
}

.text-primary{
    color: black !important;  
}

.text-yellow{
    color: black !important;  
}

.user-info{
    color: black;
    text-transform: capitalize;
    font-size: 25px; 
}

.user-info span{
     color:red;
     display: block; 
     font-size: 16px;
}

.navbar-search .input-group-text{
    padding-right: 22px;
}

span.avatar-sm i{
    padding: 10px;  
}

@media (min-width: 768px){
    .navbar-brand{
        padding-bottom: 4rem !important;
     }
} */


.navbar-search .input-group-text{
    padding-right: 22px;
}
