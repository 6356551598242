@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
.read{
    width: 15px;
    height: 15px;
    background: #6B6AE7;
    border-radius: 50%;
    position: absolute;
    margin-top: 12px;
    border: 2px solid black;
}

.unread{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    margin-top: 12px;
    border: 2px solid black;
}

.up_arrow{
    display: inline-block;
    margin: 0 2px;
    vertical-align: middle;
    border-bottom: solid 5px #EA0101;
    border-left: solid 4px transparent;
    border-right: solid 4px transparent;
}

.down_arrow{
    display: inline-block;
    margin: 0 2px;
    vertical-align: middle;
    border-top: solid 5px #02bf42;
    border-left: solid 4px transparent;
    border-right: solid 4px transparent;
}


.card{
   padding : 22px;       
}


.react-bootstrap-table thead{
    background-color: #5d6cae;
}

.react-bootstrap-table thead tr th{
    color:white;
    padding-top: 20px;
    padding-bottom: 20px;
}

.react-bootstrap-table tbody{
   border: 0.5px solid #ebe3e3;
}
.react-bootstrap-table{
    margin-bottom: 15px;
}

div.ag-header-row{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #5d6cae;
    color: white !important;
}

.ag-theme-alpine .ag-root-wrapper{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 1px 6px 1px 1px #ede9e9;
}

.ag-theme-alpine .ag-header{
    height: 65px;
    min-height: 65px;
}

.ag-header-row{
    height: 76px;
}

.ag-react-container div button:first-child{
    padding-top: 6px;
}

.ag-react-container div button:nth-child(2){
    margin-bottom: 10px;
}


/* // toggle button */
.custom-toggle input:checked + .custom-toggle-slider:before{
    background-color: white;
}

.custom-toggle input:checked + .custom-toggle-slider{
    background-color: #02bf42; 
    border:#02bf42
}


.ag-header-container{
    background-color: #6E68E7;
}

.ag-header-cell-text{
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
}
.ag-icon-filter{
    color: #fff;
}

.all_btn{
    width: 126px;
    height: 36px;
    left: 258px;
    top: 180px;
    background: #6B6AE7;
    border-radius: 8px 0px 0 8px;
    padding: 7px 50px;
    cursor: pointer;
    /* position: fixed; */
}

.all_txt{
    /* position: absolute; */
width: 29px;
height: 28px;
left: 307px;
top: 184px;

font-family: 'Inter', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 35px;

/* color: #FFFFFF; */
}

.new_btn{
    width: 126px;
    /* position: fixed; */
    padding: 7px 50px;
    height: 36px;
    left: 436px;
    top: 181px;
    background: #D1D4F7;
    cursor: pointer;
}

.fav_btn{
    cursor: pointer;
    padding: 7px 50px;
    /* position: fixed; */
    width: 126px;
    height: 36px;
    left: 561px;
    top: 181px;
    background: #D1D4F7;
    border-radius: 0px 8px 8px 0px;
}

#primary{
    color: #fff;
    background: #6b6ae7;
}

#secondary{
    color: #000;
    background: #D1D4F7;
}


/* navbar main */
/* .navbar-top{
    background: linear-gradient(360deg, #88FDDE  0%, #08A9A8 80%);

 
} */
.auth{
    background: inherit !important;
}
/* header */
.header{
    background: #fff !important;
}
.mb-0{
    color:#3F3F3F;
}

/* filter input */
.ag-input-wrapper{
    color:grey;
}


/* profile page */
.bg-gradient-info{
    background: unset !important;
}


#sidenav-main {
   
   /* background: linear-gradient(180deg, #CCE7FF 10%, #DBF9FF 100%); */
   background:#5d6cae  !important;
   color: #ffffff !important;
}

#sidenav-main h1 {

/* font-family: 'Heebo'; */
font-style: normal;
font-weight: 600;
font-size: 33px;
line-height: 53px;
/* identical to box height */
margin-bottom: 25%;
margin-top: 0%;
/* color: #5d6cae; */
color: #fff;

}
.dash-button {
    color: #fff;
    /* background: #5d6cae;
    border: #5d6cae; */
    border-radius:25px;
    background: #5d6cae;

}
.phase-button {
    background: #fff;
    color: #3F3F3F;
    border: 1px solid #5d6cae;
    border-radius:25px;

}
.am-link{
    color:#5d6cae;
    text-align:left;
    /* padding-left: 0px; */


line-height: 22px;
/* identical to box height */

text-decoration-line: underline;
}
.dash-analy{
    background: #F4F4F4;
border-radius: 8px;
padding: 1%;
/* transform: matrix(1, 0, 0, -1, 0, 0); */
}
.dash-col-analy,.dash-col-analy-3,.dash-col-analy-4 .active {
    background-color: #77fff81f !important;
}
.dash-col-analy,.dash-col-analy-3,.dash-col-analy-4 .dash-inner-row {
    background: #FFFFFF;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
border-radius: 16px;
max-width: 100%;
margin:0.5%;

}
.clientformtag{
    text-align: left;
}

.dash-col-analy{
    flex :0 0 19.66667% !important;
    max-width:  15%;
}
.dash-col-analy-3{
    flex :0 0 22.66667% !important;
    max-width:  25%;
}
.dash-col-analy-4{
    flex :0 0 32.66667% !important;
    max-width:  33%;
}
.dash-inner-row{
    padding: 6%;
    /* width: 320px !important; */
}
.text-dash{
    color:#3F3F3F;
    font-size: 12px;
}
.dash-inner h1 {
    font-size: 32px;
}
.dash-inner h2 {
    font-size: 20px;
}
.card-header{
    padding: 0;
}
.main-content{
    background: #ffff;
}
.sortclass{
    cursor: pointer;
}
.sortarrow{
    float: right;
    margin-top: 7px;
}
.up-arrow {
    width: 0;
    height: 0;
    border: solid 3px transparent;
    background: transparent;
    border-bottom: solid 4px black;
    border-top-width: 0;
    cursor: pointer;
    font-size:"6px"
}

.down-arrow {
    width: 0;
    height: 0;
    border: solid 3px transparent;
    background: transparent;
    border-top: solid 4px black;
    border-bottom-width: 0;
    margin-top:1px;
    cursor: pointer;
}
.filter-button{
    background: #F6F6F6 !important;
    border:#F6F6F6;
    border-radius:5px;
    color:#3F3F3F;
    padding: 6px !important;
}
.filter-button on:hover{
    background: #F6F6F6 !important;
    border:#F6F6F6;
    border-radius:5px;
    color:#3F3F3F
}
.taskdetailmodal{
    max-width :80%
}
.whitespace{
    white-space :initial !important;
}
.table td, .table th{
    /* white-space :unset !important; */
    padding: 7px 10px !important;
}
 /* tr:nth-child(3n+2) {
    background: #EDF7FF; 
    border-left: 7px solid #0060B8;
    border-radius: 4px 0px 0px 4px;
   } 
   tr:nth-child(3n+1) {
    background: #FFF9F2;
    border-left: 7px solid #F28705;
    border-radius: 4px 0px 0px 4px;
   } 
   tr:nth-child(3n) {
    background: #FEF5FF;
    border-left: 7px solid #D557EA;
    border-radius: 4px 0px 0px 4px;
   }
    */
    .form-control:disabled, .form-control[readonly] {
        background-color: #ffffff;
        opacity: 1;
        color: #9c9c9c;
    }
    .disablerow {
        color: #e9ecef;
    }
    .edit-row span {
        padding: 10px;
        color:#878787;
        margin-right:25px;
        font-size: 14px;
    }
.navbar-top h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: #444444;
}
.navbar-top .noti-span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #F24C05;
    cursor: pointer;
}
.nav-link{
   color: #ffffff !important;
   /* margin-left: 6.3%; */
   font-weight: 600 !important;
   font-size: 16px !important;
   padding: 7% !important;
   box-shadow: none !important;
 
}
.navbar-nav{
    margin-left:-0.5rem !important;
}
.text-th{
    /* color: #3F3F3F; */
    color:#ffffff;
 
 }
 .footer{
     background: #ffffff !important;
 }
 .navbar-nav li a.active::before {
    content: "";
    top: -30px !important;
    right: 0 !important;
    left:153px !important;
    height: 30px;
    width: 30px;
    position: absolute;
    /* background: #2bdfd7; */
    border-radius: 50%;
    box-shadow: 15px 15px 0 #fff;
    border-left: 0px solid #2bdfd7 !important;
}

.navbar-nav li a.active::after {
    content: "";
    bottom: -30px;
    right: 0;
    height: 30px;
    width: 30px;
    position: absolute;
    /* background: #21d1cb; */
    border-radius: 50%;
    box-shadow: 15px -15px 0 #fff;
}
.navbar-vertical .navbar-nav .nav-link.active {
    position: relative;
    background-color: #FFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-top-left-radius: 30px;
   
    border-bottom-left-radius: 30px;
    color: #000 !important;
}
.blue-row{
    background: #EDF7FF; 
    border-left: 7px solid #0060B8;
    border-radius: 4px 0px 0px 4px;

}
.orange-row{
    background: #FFF9F2;
    border-left: 7px solid #F28705;
    border-radius: 4px 0px 0px 4px;
}.red-row{
    background: #fff2f2;
    border-left: 7px solid #f20505;
    border-radius: 4px 0px 0px 4px;
}
.purple-row{
    background: #FEF5FF;
    border-left: 7px solid #D557EA;
    border-radius: 4px 0px 0px 4px;
}
.table .thead-light th {
    text-transform :none;
    background-color: #fff;
    color: #3F3F3F;
border: 2px solid #FFFFFF;
font-size: 14px;
}
.table th, .table td{
    padding:10px;
}
.table td,th {
border: 2px solid #FFFFFF;
}
.notify-footer{
   background-color: #21c1c7;
   padding: 20px;
}

.notify-footer span{
   color:white;
   font-size: 18px;
   cursor: pointer; 
}

.notify-header{
   display: block;
   border-bottom: 1px solid #e9e9e9;
   /* border-bottom: 1px solid grey;    */
}
.header-right-el{
    color: #21c1c7;
    font-size: 20px;
}
.header-right-el span{
   cursor: pointer;  
}

.header-right-el span:nth-child(2){
    margin-left: 50px;     
}

.btn-comment{
    background-color: #6b6ae7; 
    color: #e9e9e9; 
}

.btn-status{
    background-color: darkorange;
    color: #e9e9e9; 
}

.btn-c-style{
    width:160px;
}

.notify-count{
    border: 1px solid #cd2e2e;
    position: absolute;
    top: -11px;
    right: -4px;
    padding-top: 6px;
    text-align: center;
    background-color: #cd2e2e;
    color: white;
    font-size: 13px;
    width: 28px;
    border-radius: 50px;
    height: 28px;  
}
.filter{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-control:disabled{
    background-color: #f1f1f16e;  
}
.btn-icon-clipboard{
    padding: 10px 20px 10px 20px;
    background-color: #F4F4F4;
    font-family: 'Inter';
}

.report-view-btn{
    border: 1px solid #5d6cae;
    background:none;
    color: #5d6cae;
    border-radius:18px;
    padding: 4px 16px;
    transition: all 0.25s;     
}

.report-view-btn:hover{
    border: 1px solid #5d6cae;
    background: #5d6cae;
    color: #ffffff;
    box-shadow: 0px 2px 10px 0px hsl(0deg 0% 15% / 29%);  
    transition: all 0.25s;
}
.btn-primary:hover {
    color: #fff;
    background-color: #FFB300; 
    border-color: #FFB300;
}
.btn-primary {
    color: #fff;
    background-color: #5d6cae;
    border-color: #5d6cae;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.custom-style tbody tr{
    /* box-shadow: inset 0px -3px 4px 0px hsl(0deg 0% 15% / 20%); */
    transition: all 0.25s;
 }

.custom-style tbody tr:hover{
   box-shadow: inset 0px -3px 4px 0px hsl(0deg 0% 15% / 8%);
   transition: all 0.25s;
}

.custom-style thead th{
   padding: 16px 10px !important; 
}

.custom-style tbody td{
    padding: 16px 10px !important;
}

.custom-style tbody tr td span.complete,span.allinforeceived,span.missinginfobutjobcanbestarted{
  color:#09a8a9;   
}

.custom-style tbody tr td span.inprogress{
  color:#fea732;   
}

.custom-style tbody tr td span.onhold,span.missinginfo{
    color:#ff1717;   
 }
 .chatdiv{
    background-color:#5d6cae;
    padding:3px;
    font-size:24px;
    border-radius:50px 50px;
    margin-right:40px;

 }
 .countspan{
    position: absolute;
    background-color: black;
    color: white;
    padding: 0px 3px;
    font-size: 10px;
    border-radius: 50px;
 }
 .chat-btn{
    border-radius:5px;
    padding:5px;
    color:#08A9A8;
    background:#D5F2F0;
    justify-content:center;
    font-family:'Inter';
    font-style:normal;
    font-weight:400;
    font-size:12px;
    border-color:#D5F2F0 ;
 }
 .chat-button{
    border-radius:30px;
    padding:5px;
    display:flex;
    margin-left:auto;
    width:300px;
 }
.view-all-btn{
    justify-content: center;
    text-align: center;
    padding: 10px;
    margin-top: 40px;
    background: #E1FFFD;
    border-radius: 0px 0px 8px 8px;
}
.hr-line{
height: 0px;
margin-top: 30px;
border: 1px solid #C4E6E3;

}
#chat{
	padding-left:0;
	margin:0;
	list-style-type:none;
	overflow-y:scroll;
	border-top:1px solid rgb(48 201 192 / 19%);
	/* border-bottom:1px solid rgb(48 201 192 / 19%); */
}
#chat li{
	padding:10px 30px;
}
#chat h2{
	display:inline-block;
	
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #404040;
}
#chat h3{
    margin-left:  5px;
	color:#bbb;
    display:inline-block;
	font-size:13px;
	font-weight:normal;
    font-family: 'Inter';
    font-style: normal;
}
#chat .entete{
	margin-bottom:5px;
}
#chat .message{
	padding:10px;
	color:#fff;
	line-height:25px;
	
	display:inline-block;
	text-align:left;
	border-radius:5px;
}
#chat .me{
	text-align:right;
}
#chat .you .message{
	background-color:#F2F3F7;
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;

color: #404040;
}
#chat .me .message{
	
background: #E1FFFD;
border-radius: 6px;
line-height: 19px;
margin-left: 80px;
color: #404040;
white-space: break-spaces;
}
#chat .triangle{
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 10px 10px 10px;
}
#chat .you .triangle{
		border-color: transparent transparent #58b666 transparent;
		margin-left:15px;
}
#chat .me .triangle{
		border-color: transparent transparent #6fbced transparent;
		margin-left:375px;
}
.arrowdiv{
   
    background-color: #08A9A8;
    border-radius: 50px 50px;
}
.arraow{
    color:white;
}
.main-modal{

    position: fixed;
    bottom: 0;
    right: 0;
    height:400px;width:s450px;
}
.btn-div{

    justify-content:center;
    gap:10px;
}
.time-text{
    color:#a19d9d;
    font-weight:600;
    line-height:0.5;
    font-size:14px;
}
.time-txt{
    margin:0;
    color:#a19d9d;
    font-weight:600;
    line-height:1.1;
    font-size:14px;
}
.tab-active{
    box-shadow: 1px 1px 1px 1px #63a99c !important;
}

@media (min-width: 768px){
.navbar-vertical .navbar-collapse:before {
    content: '';
    display: block;
    margin: 0rem -1rem;
}

.sider-logo{
    /* background: linear-gradient(360deg, #88FDDE  0%, #08A9A8 80%) !important; */
    margin-left: -37px;
    margin-top: -26px;
    line-height: 4;
    margin-right: -24px!important;
}
.theme-logo{
    margin-top: 18px;
}
.add-btn-ul{
    position: absolute;
    left: 2%;
    right: 76%;
    top: 79.49%;
    bottom: 0%;
    background: #6E68E7;
}
.edit-btn-ul{
    position: absolute;
    left: 2%;
    right: 82%;
    top: 79.49%;
    bottom: 0%;
    background: #6E68E7;
}
}

@media only screen and (max-width: 600px) {
    .notify-container {
      display: none;
    }
    #navbar-main{
        display: none;
    }
    .all_btn{
        width: 126px;
        height: 36px;
        left: 258px;
        top: 180px;
        background: #6B6AE7;
        border-radius: 8px 0px 0 8px;
        padding: 7px 30px;
        cursor: pointer;
        /* position: fixed; */
    }
    
    
    .new_btn{
        width: 126px;
        /* position: fixed; */
        padding: 7px 30px;
        height: 36px;
        left: 436px;
        top: 181px;
        background: #D1D4F7;
        cursor: pointer;
    }
    
    .fav_btn{
        cursor: pointer;
        padding: 7px 30px;
        /* position: fixed; */
        width: 126px;
        height: 36px;
        left: 561px;
        top: 181px;
        background: #D1D4F7;
        border-radius: 0px 8px 8px 0px;
    }
  }


  .browse-icon{
     font-size: 5.4rem;
     color: #5d6cae;
  }
  .browse-title{
    font-size: 1.5rem;
  }
  .browse-btn{
    color: #ffffff !important;
    background-color: #5d6cae !important;
    font-weight: 450;
    font-size: 1.2rem;
  }

  .progress_css div{
    border-radius: 0px 0px 0px 0px !important;
  }
  
  .progress_css div div span{
    padding: 5px 16px !important;
  }

  .dz-ui-label{
    color: #5d6cae !important;
    font-family: inherit !important;
    font-size: 28px !important;
  }

  .dropzone-ui-extra {
    border: 1px dashed #d8d8df !important;  
  }
/* @media screen and (min-width: 1024px) and (max-height: 1310px) {
    .dash-col-analy{
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        width: 23% !important;
        height: 140px;
        margin:1%;
        max-width: 100%;
    }
    .dash-button {
        color: #fff;
        background: #05A8A8;
        border: #05A8A8;
        border-radius: 25px;
    }
}
 */


 .info-avatar {
    height: 45px;
    width: 45px;
    float: left;
    margin:3px 10px 0 0;
  }
  
  .info-avatar .ellipse {
    background-color: #d5f2f0;
    border-radius: 22.5px;
    height: 45px;
    left: 0; 
    position: relative;
    top: 0;
    width: 45px;
    text-align:center;
    padding-top : 6px;
    color:#186a66;
    font-weight: 600;
    font-size:20px;
  }
  .navbar-search .form-control {
    width: 215px;
    background-color: transparent;
}
.msg-label{
    padding: 14px 12px;
    color: #3f3f3f;
}
 #whatsapp-chat{background:#fff;width:350px;border-radius:10px;bottom:90px;right:30px;z-index:99;animation-name:showchat;animation-duration:1s;transform:scale(1)}
 .blantershow-chat{background:linear-gradient(to right top,#6f96f3,#164ed2);color:#fff;position:fixed;z-index:98;bottom:25px;right:30px;font-size:15px;padding:10px 20px;border-radius:30px;box-shadow:0 1px 15px rgba(32,33,36,.28)}a.blantershow-chat i{transform:scale(1.2);margin:0 10px 0 0}
 .header-chat{color:#000;padding:0px 12px;border-bottom:1px solid rgb(48 201 192 / 19%);}
 .header-chat h3{margin:0 0 10px}
 .header-chat p{font-size:14px;line-height:1.7;margin:0}
 /* .info-avatar{position:relative}
 .info-avatar img{border-radius:100%;width:50px;float:left;margin:0 10px 0 0}
 .info-avatar:before{z-index:1;font-family:"Font Awesome 5 Brands";color:#fff;padding:4px 5px;border-radius:100%;position:absolute;top:30px;left:30px} */
 a.informasi{padding:12px;display:block;overflow:hidden;animation-name:showhide;animation-duration:2.5s}
 a.informasi:hover{background:#f1f1f1}
 a.informasi.active{background: #f1f1f1}

 .info-chat span{display:block}#get-label,span.chat-label{font-size:12px;color:#888}#get-nama,span.chat-nama{margin:5px 0 0;font-size:15px;font-weight:700;color:#222}#get-label,#get-nama{color:#fff}span.my-number{display:none}
 .blanter-msg{color:#444;padding:1px 1px;font-size:12.5px;text-align:center;}textarea#chat-input{border:none;font-family:'Arial',sans-serif;width:100%;height:20px;outline:none;resize:none}
 a#send-it{color:#F2F3F7;margin:5px 0 0 5px;
    position: absolute;
    right: 8px;
    bottom: 12px;
    height: fit-content;font-weight:700;padding:8px;background:#5d6cae;width:12%;border-radius:20px}
 .first-msg{background:#f5f5f5;padding:30px;text-align:center}.first-msg span{background:#e2e2e2;color:#333;font-size:14.2px;line-height:1.7;border-radius:10px;padding:15px 20px;display:inline-block}.start-chat .blanter-msg{display:flex}#get-number{display:none}
 a.close-chat{    position: absolute;
    top: 8px;
    color: #0000007d;
    font-size: 20px;
    right: 8px;}
    @keyframes showhide{from{transform:scale(.5);opacity:0}}
    .chat-shadow{
        outline: 0;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    color: #a2aeb9 !important;
    }
    .msg-text{
        border-radius: 100px;
        border:1px solid #2ec8bf5e;
        height : 45px !important;
        margin-bottom:6px;
        padding: 10px 17px;
    }
 .btn-danger{
    padding : 5px 10px;
    border-color: #5d6cae;
 }

 .btn-success {
    padding : 5px 10px;
    color: #fff;
    background-color: #5d6cae;
    border-color: #5d6cae;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.btn:hover {
    color: #fff;
    background-color: #6cc5f0; 
    border-color: #6cc5f0;
}
 .btn-info {
    padding : 5px 10px;
 }
 
 .pagination {
    flex-wrap: wrap!important;
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #5d6cae;
    border-color: #5d6cae;
}
.btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #5d6cae;
    border-color: #5d6cae;
}
@keyframes marquee {
    0% {
      margin-left: 100%;
      transform: translateX(0%);
    }
    100% {
      margin-left: 0;
      transform: translateX(-100%);
    }
  }
  
  .section {
    overflow: hidden;
    background-color: #CCE7FF;
    .marquee {
      animation: marquee 50s linear infinite;
      display: inline-block;
      white-space: nowrap;
  
      &:hover {
        animation-play-state: paused;
      }
    }
  }
  
  .text-styling {
    font-weight: bold;
    color:#5d6cae;
    margin:0;
    padding: 0.5em;
  }
  .text-styling-check {
    font-weight: bold;
    color:red;
    margin:0;
    padding: 0.5em;
  }
  .bg-default {
    background-color: #5d6cae !important;
}
  .activesubmenu{
    color:rgb(240, 146, 37) !important
  }
  .table-container {
    overflow-x: auto;
  }
  .table {
    width: 100%;
    border-collapse: collapse;
  }

  th, td {
    padding: 8px 16px;
    text-align: left;
    border: 2px solid #FFFFFF;
  }
  
  .sticky-col {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    background-color: #f8f9fa; /* Match the thead background */
    z-index: 2;
    box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.3);
  }

  .sticky-col-1 { left: 0; }
  .sticky-col-2 { left: 36px; }
  .sticky-col-3 { left: 250px; }
  .sticky-col-4 { left: 350px; }
  .sticky-col-5 { left: 450px; }

  .first-col { width: 50px; }
  .second-col { width: 50px; }
  .third-col { width: 100px; }
  .fourth-col { width: 100px; }
  .fifth-col { width: 100px; }

  .scroll-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Optionally hide vertical scrollbar */
    white-space: nowrap; /* Prevent line breaks */
    width: 100%; /* Adjust width as needed */
  }
  
  .scroll-content {
    display: inline-block; /* Ensure it wraps content properly */
    width: auto; /* Adjust width as needed */
  }
.scroll-button{
    background-color: #5d6cae;
    color: white; 
    border: 1px solid white;
} 
.scroll-div{
   position: fixed;
   bottom : 40px;
   right : 20px;
   width:55px;
} 
.tabactive{
    background-color:'#5d6cae' !important;
    color:'#fff';

    margin: '7px 0px';
}
.btn-secondary:hover {
    color: #000;
    background-color: #6cc5f0; 
    border-color: #6cc5f0;
}
.word-wrap-container {
    overflow-wrap: break-word;
    word-wrap: break-word; /* for legacy browser support */
    word-break: break-word; /* Optional: This breaks the word itself at the boundaries */
  }
/* Make the scroll container scrollable */
.scroll-container {
    /* width: 100%; */
    height: 900px; /* Set the height of the scrollable area */
    overflow: auto; /* Enable scrolling */
  }
  
  /* Table styling */
  .sticky-table {
    /* width: 100%; */
    border-collapse: collapse;
    table-layout: fixed; /* Ensure the columns are fixed */
  }
  
  /* Sticky header styling */
  .sticky th {
    position: sticky;
    top: 0; /* Stick to the top of the container */
    background-color: #fff; /* Background color to avoid transparency */
    z-index: 15; /* Make sure the header stays above the content */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: shadow effect */
    padding: 10px; /* Padding for better visual spacing */
    /* text-align: left;  */
    /* border: 1px solid #ddd;  */
  }
  
  /* Optional: Add styles to table cells */
  /* .sticky td, .sticky th {
    padding: 8px;
    border: 1px solid #ddd;
  }
  .sticky-table th, .sticky-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }   */